import React from "react";
import { CmsContent, Container, Image, Layout, SocialsSharing } from "components";
import { FeaturedPosts, Hero, Loader } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { ContainerSpacingEnum, ContainerWidthEnum } from "utils";
import { Post_By_IdQuery } from "codegen";
import "./single-post.scss";
import { graphql } from "gatsby";

interface BlogPostTemplate {
  data: Post_By_IdQuery;
}

const BlogPostTemplate = ({ data: { relatedPosts, post, wp } }: BlogPostTemplate): JSX.Element => {
  const { title, seo, acfLayout } = post;
  const {
    webAppSettings: {
      webAppSettings: { postSharingOptions },
    },
  } = wp;
  const relatedItems = relatedPosts?.nodes;

  return (
    <Layout pageTitle={title} seo={seo}>
      <>
        <Hero
          data={{
            content: `<p className="typography--h1">Blog</h1>`,
            compact: true,
            theme: "PRIMARY",
          }}
          location={seo?.breadcrumbs}
        />
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={seo?.breadcrumbs}
          />
        ) : (
          <div className="single-post">
            {post.content && (
              <Container
                containerWidth={ContainerWidthEnum["LAYOUT_EXTRA_SMALL"]}
                containerSpacing={ContainerSpacingEnum["SECTION_SMALL"]}
              >
                <h1 className="single-post__title">{post.title}</h1>
                {post?.featuredImage && (
                  <Image src={post?.featuredImage?.node} className="single-post__featured-image" />
                )}
                <p className="single-post__date">Posted on {post?.date}</p>
                {/* Using CmsContent here incase we're using shortcodes */}
                <div className="single-post__content">
                  <CmsContent content={post.content} />
                </div>
              </Container>
            )}
          </div>
        )}
        {postSharingOptions && <SocialsSharing socialItems={postSharingOptions} />}
        <FeaturedPosts data={{ feedItems: relatedItems }} />
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "posts" })(BlogPostTemplate);

export const PostByIdQuery = graphql`
  query POST_BY_ID($id: String!, $previousPostId: String, $nextPostId: String, $relatedPosts: String) {
    post: wpPost(id: { eq: $id }) {
      ...PostFields
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    relatedPosts: allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { nodes: { elemMatch: { id: { eq: $relatedPosts } } } } }
      limit: 3
    ) {
      nodes {
        ...PostFields
      }
    }
    wp {
      webAppSettings {
        webAppSettings {
          postSharingOptions
        }
      }
    }
  }
`;
